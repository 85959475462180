/* Coustom */
body {
  background-color: #2C2B30;
}
/* @media only screen and (max-width: 991px) {
  .sabuj_ml_tb_2 {
    margin-left: 0.5rem !important;
  }

} */
