img {
  object-fit: cover;
}

p {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 14px;
}

.pointer {
  cursor: pointer;
}

.bg-circle {
  /* width: px; */
  background-color: gray;
  border-radius: 50%;
}
.bg-circle img {
  width: 35px;
}
.bg-circle-diamond{
    background-color: gray;
    border-radius: 50%;
    padding: 5px;
}
.bg-circle-diamond img {
    width: 25px;

}
textarea.form-control {
  height: 100%;
}
.category-card {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 10px;
}
.image-modal .modal-content {
  background-color: transparent;
  border: none;
}
.image-modal .modal-header {
  align-items: flex-start;
}
.image-modal .modal-header .close {
  padding: 0.875rem 1.815rem;
  margin: 0;
  position: absolute;
  right: 12px;
  float: none;
  top: 0px;
  font-size: 40px;
  font-weight: 100;
}
.image-container {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ck-content ul li{
  list-style: disc; 
}
.ck-content ul{
 padding-left: 2rem !important;
}
.ck-content ol li{
  list-style: auto; 
}
.draggable-list-container  div div{
  /* Styles for the second div */
  /* Example: */
  z-index: 0 !important;
}

.custom-checkbox-list{
  width: 20px;
  height: 20px;
}
.custom-checkbox-list .custom-control-input{
  width: 100%;
  height: 100%;
}
.link-text:hover{
color: blue;
}
.cross-icon {
  top: -6px;
  position: absolute;
  right: -6px;
  z-index: 1;
}

.toggle-switch {
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-switch.active {
  background-color: #4caf50;
}

.toggle-circle {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  transition: left 0.3s;
}

.toggle-switch.active .toggle-circle {
  left: 25px; /* Move circle to the right */
}
