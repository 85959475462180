///////////////////////////
// Sidebar profile
///////////////////////////
.nav-user {
    // text-align: center;
    background: var(--primary);
    margin-bottom: 10px;
    padding: 20px 25px 15px;
    @include custommq($min: 768px, $max: 1199px) {
        padding: 20px 15px 15px;
    }
    img {
        width: 35px;
        height: 35px; 
        @include custommq($min: 768px, $max: 1199px) {
            width: 35px;
            height: 35px;
            margin-bottom: 10px;
        }
    }
    h5 {
        margin-left: 10px;
        margin-bottom: 3px;
        color: $white;
        @include custommq($min: 768px, $max: 1199px) {
            display: none;
        }
        @at-root [data-sibebarbg="color_2"] & {
            color: $white;
        }
    } 
    p{
        margin-left: 10px;
        margin-bottom: 8px;
        color: #afcff7;
        @include custommq($min: 768px, $max: 1199px) {
            display: none;
        }
    }

    @include custommq($min: 768px, $max: 1199px) {
        i{
            margin-top: 15px;
            display: block
        }
    }    
}





.menu-toggle{
    .nav-user {
        padding: 20px 15px 15px;
        img {
            width: 35px;
            height: 35px;
            margin-bottom: 10px;
        }
        h5,
        p {
            display: none;
        }
        i {
            margin-top: 15px;
            display: block
        }
        .dropdown-menu {
            left: 45px !important;
            top: 22px !important;
        }
    }
}

